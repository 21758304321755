<div class="logo">
  <div class="centered">
    <div class="mr-10 mb-30">
      <h1 class="ta-center">SORRY, PAGE WAS NOT FOUND</h1>
      <h4 class="ta-center">
        The page you are looking for might have been removed, had it's name changed, or is temporarily unavailable
      </h4>
    </div>
    <div class="button-wrapper">
      <button mat-raised-button color="primary" class="fcolor-white goBackButton" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
    </div>
  </div>
</div>
