<div class="dialog-header">
  <div class="title">
    <ng-content select="[dialog-title]"></ng-content>
  </div>
</div>

<div class="dialog-content">
  <ng-content select="[dialog-content]"></ng-content>
</div>

<div class="dialog-footer">
  <ng-content select="[dialog-footer]"></ng-content>
</div>
